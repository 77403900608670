<template>
  <div id="clothings" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="clothings" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ clothings.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Amount" v-slot="props">{{
          props.row.amount
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-clothing"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      schoolId: null,
      pageTitle: 'Added Services',
      clothings: [],
      isPaginated: true,
      id: null,
    }
  },
  methods: {
    initializeInfo(clothing) {
      // this.$router.push(`/school/${this.schoolId}/clothing_info/${clothing.id}`)
    },
    initializeUpdate(clothing) {
      this.$router.push(`/school/${this.schoolId}/clothing/${clothing.id}`)
    },
    initializeRemove(clothing) {
      this.id = parseInt(clothing.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteClothing($id: Int!) {
              deleteClothing(input: { id: $id }) {
                clothing {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteClothing.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.clothings.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.schoolId}/fee_management`,
      },
      {
        name: 'New',
        route: `/school/${this.$route.params.school_id}/clothing/new`,
      },
    ])

    this.$apollo.addSmartQuery('clothings', {
      query: gql`
        query ClothingsQuery($schoolId: ID!) {
          clothings(schoolId: $schoolId) {
            id
            name
            amount
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.clothings.refetch()
  },
}
</script>
